function setUpDesktopNavScroll(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            setUpDesktopNavScroll(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('DesktopNavScroll', {
        $siteHeader: null,

        model: {
            isScrolled: 'bool'
        },

        init: function() {
            // Save a reference to the controller
            var self = this;

            // Get the site header
            self.$siteHeader = $('.js-site-header');

            // Watch for changes to isScrolled
            self.model.onChange('isScrolled', function(val) {
                if (val) {
                    self.setIsScrolled();
                    return;
                }
                self.setIsNotScrolled();
            });

            // On scroll
            self.$el.on('scroll.desktopNavScroll', function() {
                self.checkScroll();
            });

            // Check initial scroll after a moment
            setTimeout(function() {
                self.checkScroll();
            }, 500);
        },

        checkScroll: function() {
            // Save a reference to the controller
            var self = this;

            // Set is scrolled
            self.model.set('isScrolled', self.$el.scrollTop() > 66);
        },

        setIsScrolled: function() {
            // Save a reference to the controller
            var self = this;

            self.$siteHeader.addClass('site-header__is-scrolled');
        },

        setIsNotScrolled: function() {
            // Save a reference to the controller
            var self = this;

            self.$siteHeader.removeClass('site-header__is-scrolled');
        }
    });
}

setUpDesktopNavScroll(window.FAB, window);
