function setUpTabbedContent(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            setUpTabbedContent(F, W);
        }, 10);
        return;
    }

    // jQuery Window
    var $W = $(W);

    // HTML Body jQuery object
    var $htmlBody = $('html, body');

    // Create the controller
    F.controller.make('TabbedContent', {
        animationTime: 100,
        desktopSwitch: 1000,
        initialRun: false,

        model: {
            activeTabId: 'string',
            mode: 'string'
        },

        events: {
            'click .js-tabbed-content__activator': function(e) {
                // Save a reference to the controller
                var self = this;

                // Get the element
                var $el = $(e.currentTarget);

                // Set the menu active state
                self.model.set(
                    'activeTabId',
                    $el.data('tabId')
                );
            }
        },

        init: function() {
            // Save a reference to the controller
            var self = this;

            // Respond to active tab ID changes
            self.model.onChange('activeTabId', function(val) {
                self.activateTab(val);
            });

            // Set initial mode
            self.setMode();

            // Set the mode based on window width
            $W.on('resize', function() {
                self.setMode();
            });
        },

        setMode: function() {
            // Save a reference to the controller
            var self = this;

            // Initial mode
            var initialMode = self.model.get('mode');

            // Set mode
            self.model.set(
                'mode',
                W.innerWidth >= self.desktopSwitch ?
                    'desktop' :
                    'mobile'
            );

            // Set initial tab
            if (self.model.get('mode') !== initialMode) {
                self.setInitialTab();
            }
        },

        setInitialTab: function() {
            // Save a reference to the controller
            var self = this;

            // Create a date/id map
            var dateIdMap = {};

            // Get tab bodies
            var $tabBodies = self.$el.find('.js-tabbed-content__body');

            // Set the initial active tab ID
            var tabId = $($tabBodies.get(0)).attr('id');

            // Get current timestamp
            var now = Math.floor(Date.now() / 1000) - 64800;

            // Set a variable for sorted keys
            var sortedKeys;
            var newSortedKeys = [];

            // Set a variable for the date based key
            var dateBasedKey;

            // Set initial run
            self.initialRun = true;

            // Iterate through the tabs and check for date based tab
            $tabBodies.each(function() {
                // Cast this item
                var $el = $(this);

                // Get the date based date
                var dateBasedDate = $el.data('dateBasedDate');

                // Add it to the array map if it exists
                if (dateBasedDate) {
                    dateIdMap[dateBasedDate] = $el.attr('id');
                }
            });

            // Get the date map keys and sort them
            sortedKeys = Object.keys(dateIdMap);
            sortedKeys.sort();

            // Remove keys that are past the date
            for (var key in sortedKeys) {
                // noinspection JSUnfilteredForInLoop

                if (parseInt(sortedKeys[key]) < now) {
                    // noinspection JSUnfilteredForInLoop
                    newSortedKeys.unshift(parseInt(sortedKeys[key]));
                }
            }

            // Set the date based key
            dateBasedKey = newSortedKeys[0];

            // If there is a date based key, make that the tab ID
            if (dateBasedKey) {
                tabId = dateIdMap[dateBasedKey];
            }

            // Set the active tab ID
            self.model.set('activeTabId', tabId);
        },

        activateTab: function(tabId) {
            // Save a reference to the controller
            var self = this;

            // Enable animation
            var enableAnimation = ! self.initialRun &&
                self.model.get('mode') === 'mobile';

            // Get activators
            var $activators = self.$el.find('.js-tabbed-content__activator');

            // Get bodies
            var $bodies = self.$el.find('.js-tabbed-content__body');

            // Deactivate all inactive tabs and activate active tabs
            $activators.each(function() {
                // Cast this element
                var $el = $(this);

                // Get the active class
                var activeClass = $el.data('activeClass');

                // Get this tab ID
                var thisTabId = $el.data('tabId');

                // Check what action to take
                if (activeClass && thisTabId !== tabId) {
                    $el.removeClass(activeClass);
                } else if (activeClass && thisTabId) {
                    $el.addClass(activeClass);
                }
            });

            // Deactivate inactive bodies and activate active bodies
            $bodies.each(function() {
                // Cast this element
                var $el = $(this);

                // Get this tab ID
                var thisTabId = $el.attr('id');

                // Get data attribute on whether this tab is active
                var thisTabIsActive = $el.data('isActive');

                // Check what action to take
                if (thisTabId !== tabId && thisTabIsActive) {
                    // Hide the content
                    if (enableAnimation) {
                        $el.slideUp(self.animationTime);
                    } else {
                        $el.hide();
                    }

                    // Set the tab data as inactive
                    $el.data('isActive', false);
                } else if (thisTabId === tabId && ! thisTabIsActive) {
                    // Show the content
                    if (enableAnimation) {
                        $el.slideDown(self.animationTime);
                    } else {
                        $el.show();
                    }

                    // Set the tab data as active
                    $el.data('isActive', true);
                }
            });

            // If enableAnimation is enabled
            if (enableAnimation) {
                if (enableAnimation) {
                    setTimeout(function() {
                        $htmlBody.animate({
                            scrollTop: self.$el.find('#' + tabId + '-tab').offset().top
                        });
                    }, self.animationTime + 50);
                } else {
                    $htmlBody.animate({
                        scrollTop: self.$el.find('#' + tabId + '-tab').offset().top
                    });
                }
            }

            // Make sure initialRun is off
            self.initialRun = false;

            // Trigger match height
            F.GlobalModel.set(
                'triggerMatchHeight',
                F.GlobalModel.get('triggerMatchHeight') + 1
            );
        }
    });
}

setUpTabbedContent(window.FAB, window);
