function setUpMobileNav(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            setUpMobileNav(F, W);
        }, 10);
        return;
    }

    // jQuery Window
    var $W = $(W);

    // Create the controller
    F.controller.make('MobileNav', {
        /** @var {Integer} animationTime */
        animationTime: 100,

        /** @var {Integer} desktopSwitch */
        desktopSwitch: 1000,

        /**
         * Modeled data for this controller
         * @var {Object} model
         */
        model: {
            mobileMenuIsActive: 'bool',
            mode: 'string'
        },

        /**
         * Events to automatically be bound
         * @var {Object} events
         */
        events: {
            'click .js--mobile-nav__activator': function() {
                // Save a reference to the controller
                var self = this;

                // Set the menu active state
                self.model.set(
                    'mobileMenuIsActive',
                    ! self.model.get('mobileMenuIsActive')
                );
            }
        },

        /**
         * Runs on controller construction
         */
        init: function() {
            // Save a reference to the controller
            var self = this;

            // Set initial mode
            self.setMode();

            // Watch for mobileMenuIsActive events
            self.model.onChange('mobileMenuIsActive', function(val) {
                // Check if we're in mobile mode
                if (self.model.get('mode') === 'desktop') {
                    return;
                }

                // If val (true) open the menu, otherwise close it
                if (val) {
                    self.openMobileMenu();
                } else {
                    self.closeMobileMenu();
                }
            });

            // Watch for mode events
            self.model.onChange('mode', function(val) {
                if (val === 'desktop') {
                    self.switchToDesktopMode();
                }
            });

            // Set the mode based on window width
            $W.on('resize', function() {
                self.setMode();
            });
        },

        /**
         * Set mode
         */
        setMode: function() {
            // Save a reference to the controller
            var self = this;

            // Set mode
            self.model.set(
                'mode',
                W.innerWidth >= self.desktopSwitch ?
                    'desktop' :
                    'mobile'
            );
        },

        /**
         * Open mobile menu
         */
        openMobileMenu: function() {
            // Get the activator
            var $activator = $('.js--mobile-nav__activator');

            // Add the active class to the activator
            $activator.addClass($activator.data('activeClass'));

            // Animate the opening of the menu
            $('.js--mobile-nav__site-nav').slideDown(this.animationTime);
        },

        /**
         * Close mobile menu
         */
        closeMobileMenu: function() {
            // Save a reference to the controller
            var self = this;

            // Get the activator
            var $activator = $('.js--mobile-nav__activator');

            // Get the site nav element
            var $siteNav = $('.js--mobile-nav__site-nav');

            // Remove the active class from the activator
            $activator.removeClass($activator.data('activeClass'));

            // Animate the closing of the menu
            if (self.model.get('mode') === 'desktop') {
                $siteNav.attr('style', null);
                return;
            }

            $siteNav.slideUp(self.animationTime, function() {
                setTimeout(function() {
                    $siteNav.attr('style', null);
                }, self.animationTime);
            });
        },

        /**
         * Switch to desktop mode
         */
        switchToDesktopMode: function() {
            // Save a reference to the controller
            var self = this;

            // Make sure the mobile menu is closed
            self.closeMobileMenu();

            // Make sure model is set to closed
            self.model.set('mobileMenuIsActive', false);
        }
    });
}

setUpMobileNav(window.FAB, window);
