function setUpReadMore(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            setUpReadMore(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('ReadMore', {
        animationTime: 100,
        $innerElement: null,

        model: {
            isActive: 'bool'
        },

        events: {
            'click .js-read-more__activator': function() {
                // Save a reference to the controller
                var self = this;

                // Set the active state
                self.model.set(
                    'isActive',
                    ! self.model.get('isActive')
                );
            }
        },

        init: function() {
            // Save a reference to the controller
            var self = this;

            // Set the inner element
            self.$innerElement = self.$el.find('.js-read-more__inner');

            // Watch for changes to active state
            self.model.onChange('isActive', function(val) {
                if (val) {
                    self.activate();
                    return;
                }
                self.deActivate();
            });
        },

        activate: function() {
            // Save a reference to the controller
            var self = this;

            // Get the active class
            var activeClass = self.$el.data('activeClass');

            self.$el.animate({
                maxHeight: self.$innerElement.outerHeight() + 50
            }, self.animationTime);

            // Add active class if it exists
            if (activeClass) {
                self.$el.addClass(activeClass);
            }
        },

        deActivate: function() {
            // Save a reference to the controller
            var self = this;

            // Get the active class
            var activeClass = self.$el.data('activeClass');

            // Open 'er up
            self.$el.animate({
                maxHeight: 250
            }, self.animationTime);

            // Remove active class if it exists
            if (activeClass) {
                self.$el.removeClass(activeClass);
            }
        }
    });
}

setUpReadMore(window.FAB, window);
