function runMatchHeight(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runMatchHeight(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('MatchHeight', {
        desktopSwitch: 1200,
        $elements: $(),
        sortedElements: {},
        runTimer: 0,

        model: {
            mode: 'string'
        },

        init: function() {
            // Save a reference to the controller
            var self = this;

            // Set initial mode
            self.setMode();

            // Sort elements
            self.$elements.each(function() {
                // Cast the element
                var $el = $(this);

                // Get the key
                var key = $el.data('matchHeight');

                // Create the array if appropriate
                if (! self.sortedElements[key]) {
                    self.sortedElements[key] = [];
                }

                // Push the element into the array
                self.sortedElements[key].push($el);
            });

            // Run inital
            self.run();

            // Watch for window resizing
            $(W).on('resize', function() {
                self.setMode();
                self.run();
            });

            // Watch for global trigger
            F.GlobalModel.onChange('triggerMatchHeight', function() {
                self.run();
            });
        },

        setMode: function() {
            // Save a reference to the controller
            var self = this;

            // Set mode
            self.model.set(
                'mode',
                W.innerWidth >= self.desktopSwitch ?
                    'desktop' :
                    'mobile'
            );
        },

        run: function() {
            // Save a reference to the controller
            var self = this;

            // Clear previous timer
            clearTimeout(self.runTimer);

            // Clear previous min heights
            for (var el in self.sortedElements) {
                if (self.sortedElements.hasOwnProperty(el)) {
                    for (var i in self.sortedElements[el]) {
                        if (self.sortedElements[el].hasOwnProperty(i)) {
                            $(self.sortedElements[el][i]).css(
                                'min-height',
                                0
                            );
                        }
                    }
                }
            }

            // Do not proceed if this is not desktop mode
            if (self.model.get('mode') !== 'desktop') {
                return;
            }

            self.runTimer = setTimeout(function() {
                self.setHeights();
            }, 500);
        },

        setHeights: function() {
            // Save a reference to the controller
            var self = this;

            // Iterate through sorted elements
            for (var el in self.sortedElements) {
                if (self.sortedElements.hasOwnProperty(el)) {
                    if (self.sortedElements[el].length > 1) {
                        self.compareAndSetHeights(self.sortedElements[el]);
                    }
                }
            }
        },

        compareAndSetHeights: function(els) {
            var height = 0;
            var $el;
            var i;

            for (i in els) {
                if (els.hasOwnProperty(i)) {
                    $el = $(els[i]);
                    height = Math.max($el.outerHeight(), height);
                }
            }

            for (i in els) {
                if (els.hasOwnProperty(i)) {
                    $el = $(els[i]);
                    $el.css('min-height', height);
                }
            }
        }
    });
}

runMatchHeight(window.FAB, window);
