function runAnchorLink(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            runAnchorLink(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('AnchorLink', {
        animationTime: 600,
        desktopSwitch: 1000,
        desktopOffset: 84,
        $htmlBody: $(),
        href: '',
        id: '',
        $scrollTo: $(),

        model: {
            mode: 'string'
        },

        events: {
            'click': function(e) {
                // Save a reference to the controller
                var self = this;

                // Prevent the default action
                e.preventDefault();

                // Run scroll
                self.runScroll();
            }
        },

        init: function() {
            // Save a reference to the controller
            var self = this;

            // Split the href
            var hrefSplit;

            // Set initial mode
            self.setMode();

            // Set the mode based on window width
            $(W).on('resize', function() {
                self.setMode();
            });

            // Get the HTML body
            self.$htmlBody = $('html, body');

            // Set the href
            self.href = self.$el.attr('href');

            // If the href is only a hash, we can stop
            if (self.href === '#') {
                return;
            }

            // Set the ID
            hrefSplit = self.href.split('#');
            self.id = hrefSplit[hrefSplit.length - 1];

            // Set the href again
            self.href = '#' + hrefSplit[hrefSplit.length - 1];

            // Set $scrollTo
            self.$scrollTo = $('#' + self.id);
        },

        setMode: function() {
            // Save a reference to the controller
            var self = this;

            // Set mode
            self.model.set(
                'mode',
                W.innerWidth >= self.desktopSwitch ?
                    'desktop' :
                    'mobile'
            );
        },

        runScroll: function() {
            // Save a reference to the controller
            var self = this;

            // Callback has fired
            var callbackHasFired = false;

            // Determine offset
            var offset = self.model.get('mode') === 'desktop' ?
                self.desktopOffset :
                0;

            // Var set path href
            var pathHref = self.href === '#top' ? '' : self.href;

            // If there is no ID or no element we should bail out
            if (! self.id || ! self.$scrollTo.length) {
                return;
            }

            // Remove the ID from the element so we don't have page jumping
            self.$scrollTo.attr('id', null);

            // Replace history state
            W.history.replaceState(
                {},
                document.title,
                W.location.origin + W.location.pathname + pathHref
            );

            // Scroll to
            self.$htmlBody.animate({
                scrollTop: self.$scrollTo.offset().top - offset
            }, self.animationTime, function() {
                // Make sure this callback has not fired
                if (callbackHasFired) {
                    return;
                }

                // The callback has fired
                callbackHasFired = true;

                // Set the ID back on the element
                self.$scrollTo.attr('id', self.id);
            });
        }
    });
}

runAnchorLink(window.FAB, window);
