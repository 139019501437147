function setUpSpeaker(F, W) {
    'use strict';

    // Make sure Fabricator has been fully loaded
    if (! W.fabDependenciesLoaded()) {
        setTimeout(function() {
            setUpSpeaker(F, W);
        }, 10);
        return;
    }

    // Create the controller
    F.controller.make('Speaker', {
        animationTime: 400,
        $profile: null,

        model: {
            isActive: 'bool'
        },

        events: {
            'click .js-speaker__toggle': function() {
                // Save a reference to the controller
                var self = this;

                // Set the active state
                self.model.set('isActive', ! self.model.get('isActive'));
            }
        },

        init: function() {
            // Save a reference to the controller
            var self = this;

            // Set the profile item
            self.$profile = self.$el.find('.js-speaker__profile');

            // Watch for changes on isActive model property
            self.model.onChange('isActive', function(val) {
                if (val) {
                    self.activateSpeaker();
                    return;
                }
                self.deactivateSpeaker();
            });

            // Watch for changes to speaker on global model
            // deactivate this speaker
            F.GlobalModel.onChange('activeSpeaker', function(val) {
                if (val === self.model.guid) {
                    return;
                }
                self.model.set('isActive', false);
            });
        },

        activateSpeaker: function() {
            // Save a reference to the controller
            var self = this;

            // Get the previousloy active speaker
            var previousActive = F.GlobalModel.get('activeSpeaker');

            // Tell the global model we're active
            F.GlobalModel.set('activeSpeaker', self.model.guid);

            if (previousActive) {
                setTimeout(function() {
                    self.activateSpeakerActual();
                }, self.animationTime);
                return;
            }

            self.activateSpeakerActual();
        },

        activateSpeakerActual: function() {
            // Save a reference to the controller
            var self = this;

            // Add the active class
            self.$el.addClass(self.$el.data('activeClass'));

            // Slide down the profile
            self.$profile.slideDown(self.animationTime);
        },

        deactivateSpeaker: function() {
            // Save a reference to the controller
            var self = this;

            // Remove the active class
            self.$el.removeClass(self.$el.data('activeClass'));

            // Slide up the profile up
            self.$profile.slideUp(self.animationTime);

            // If the global model says this is our item still, unset it
            if (F.GlobalModel.get('activeSpeaker') === self.model.guid) {
                F.GlobalModel.set('activeSpeaker', '');
            }
        }
    });
}

setUpSpeaker(window.FAB, window);
